import { mapActions, mapState } from 'vuex';

import { actions } from '@/store/modules/token'
import store from "@/store";

mapActions('CustomAlert', ['showAlert']);
mapState(['LS_JWT']);

/**
 *
 * @param ctx {Context}
 * @param err {Object}
 * @param err.response {Object}
 * @param err.response.status {number}
 * @param custom_err {Object}[undefined]
 * @param custom_err.status {number}
 * @param custom_err.msg {string}
 * @param custom_err.type {string}
 */
export const axiosResponseError =  function (ctx, err, custom_err = undefined) {
    if (!err.response)
        return ctx.showAlert({text: ctx.$t('alerts.error.500'), type: 'error', icon: 'error'});
    if(custom_err && err.response.status === custom_err.status)
        return ctx.showAlert({text: custom_err.msg, type: custom_err.type || 'info', icon: custom_err.type || 'info'});
    if ( [400, 401, 403, 422].includes(err.response.status) ) {
        ctx.showAlert({text: ctx.$t(`alerts.error.${err.response.status}`), type: 'info', icon:'info'});
        if (err.response.status === 401) {
            actions.destroyJWT({commit: store.commit, dispatch: store.dispatch});
            ctx.$router.go();
        }
    } else if(err.response.status === 502) {
        ctx.showAlert({text: ctx.$t(`alerts.error.502`), type: 'error', icon:'error'});
    } else {
        ctx.showAlert({text: ctx.$t('alerts.error.500'), type: 'error', icon:'error'});
    }
}