module.exports = {
    ALPHA_NUMERIC_W_SPACES: /^[0-9a-zA-ZáéíóúüñÁÉÍÓÚÜÑ ]+$/i,
    ALPHA_W_SPACES: /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ ]+$/i,
    ALPHA_N_SPACES: /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ]+$/i,
    HEX: /^[0-9A-F]+$/i,
    EMAIL: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
    BLACKLIST: {
        COMMENTS: /^[^<>[\]~@$%^*()_+{}|`=\\/]+$/
    },
    FILES: {
        PDF: "application/pdf",
        IMAGES: [
            "image/png",
            "image/jpeg",
            "image/gif",
            "image/jpg",
            "image/tiff"
        ],
        FILES: [
            "image/png",
            "image/jpeg",
            "image/gif",
            "image/jpg",
            "image/tiff",
            "application/pdf",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            "audio/mpeg"
        ]
    }
};