import { EMAIL, ALPHA_W_SPACES } from "@/utils/validations/regex";
import { limits } from '@/utils/validations/limits';

/**
 * email rules validation
 * @param ctx
 * @returns {[(function(*=)), (function(*)), (function(*)), (function(*=))]}
 */
export const email = (ctx) => [
    v => !!v || ctx.$t('rules.email_required'),
    v => v.length >= limits.EMAIL_MIN_LENGTH || ctx.$t('rules.email_min'),
    v => v.length <= limits.EMAIL_MAX_LENGTH || ctx.$t('rules.email_max'),
    v => EMAIL.test(v) || ctx.$t('rules.email_invalid_format')
];

/**
 * password rules validation
 * @param ctx
 * @returns {[(function(*=)), (function(*)), (function(*))]}
 */
export const password = (ctx) => [
    v => !!v || ctx.$t('rules.password_required'),
    v => !!v && v.length >= limits.PASSWORD_MIN_LENGTH || ctx.$t('rules.password_min'),
    v => !!v && v.length <= limits.PASSWORD_MAX_LENGTH || ctx.$t('rules.password_max'),
];

/**
 * name rules validation
 * @param ctx
 * @returns {[(function(*=)), (function(*)), (function(*)), (function(*=))]}
 */
export const name  = (ctx) => [
    v => !!v || ctx.$t('rules.name_required'),
    v => v.length >= limits.NAME_MIN_LENGTH || ctx.$t('rules.name_min'),
    v => v.length <= limits.NAME_MAX_LENGTH || ctx.$t('rules.name_max'),
    v => ALPHA_W_SPACES.test(v) || ctx.$t('rules.alpha_s_format')
];

/**
 * surname rules validation
 * @param ctx
 * @returns {[(function(*=)), (function(*)), (function(*)), (function(*=))]}
 */
export const surname  = (ctx) => [
    v => !!v || ctx.$t('rules.surname_required'),
    v => v.length >= limits.SURNAME_MIN_LENGTH || ctx.$t('rules.surname_min'),
    v => v.length <= limits.SURNAME_MAX_LENGTH || ctx.$t('rules.surname_max'),
    v => ALPHA_W_SPACES.test(v) || ctx.$t('rules.alpha_s_format')
];

/**
 * photo rules validation
 * @param ctx
 * @returns {[(function(*=))]}
 */
export const photo = (ctx) => [
    v => (!v || (!!v && v.size < limits.PHOTO_MAX_SIZE)) || ctx.$t('rules.photo_max_size')
]

/**
 * thesis file rules validation
 * @param ctx
 * @returns {[(function(*=))]}
 */
export const thesis_file = (ctx) => [
    v => !!v || ctx.$t('rules.thesis_file_required'),
    v => (!!v && v.size < limits.THESIS_FILE_MAX_SIZE) || ctx.$t('rules.thesis_file_max_size')
]

/**
 * notice image rules validation
 * @param ctx
 * @returns {[(function(*=))]}
 */
export const notice_images = (ctx) => [
    v => (!v || v.every(f => f.size < limits.PHOTO_MAX_SIZE)) || ctx.$t('rules.photo_max_size'),
    v => (!v || v.length <= limits.NOTICE_MAX_IMAGES) || ctx.$t('rules.notice_file_max_files')
]

/**
 * notice file rules validation
 * @param ctx
 * @returns {[(function(*=))]}
 */
export const notice_files = (ctx) => [
    v => (!v || v.every(f => f.size < limits.NOTICE_FILE_MAX_SIZE)) || ctx.$t('rules.notice_file_max_size'),
    v => (!v || v.length <= limits.NOTICE_MAX_FILES) || ctx.$t('rules.notice_file_max_files')
]

/**
 * notice contact rules validation
 * @param ctx
 * @returns {[(function(*=))]}
 */
export const notice_contact = (ctx) => [
    v => (!v || v.length < limits.NOTICE_CONTACT_MAX_LENGTH || v.length === 0) || ctx.$t('rules.notice_contact_max_length'),
    v => (!v || v.length > limits.EMAIL_MIN_LENGTH || v.length === 0) || ctx.$t('rules.notice_contact_min_length')
]

/**
 * notice contact rules validation
 * @param ctx
 * @returns {[(function(*=))]}
 */
export const notice_expiration = (ctx) => [
    v => (!v || Object.prototype.toString.call(new Date(v)) === "[object Date]") || ctx.$t('rules.notice_expiration_date'),
    v => (!v || new Date(v).getTime() - new Date().getTime() > 12.1 * 60 * 60 * 1000) || ctx.$t('rules.notice_expiration_min'),
]

/**
 * notice information rules validation
 * @param ctx
 * @returns {[(function(*=))]}
 */
export const notice_info = (ctx) => [
    v => !!v  || ctx.$t('rules.notice_info_required'),
    v => (!!v && v.length <= limits.MAX_TEXT_LENGTH) || ctx.$t('rules.notice_info_max_length'),
    v => (!!v && v.length >= limits.NOTICE_INFO_MIN_LENGTH) || ctx.$t('rules.notice_info_min_length'),
]

/**
 * notice title rules validation
 * @param ctx
 * @returns {[(function(*=))]}
 */
export const notice_title = (ctx) => [
    v => !!v  || ctx.$t('rules.notice_title_required'),
    v => (!!v && v.length < limits.NOTICE_TITLE_MAX_LENGTH) || ctx.$t('rules.notice_title_max_length'),
    v => (!!v && v.length > limits.NOTICE_TITLE_MIN_LENGTH) || ctx.$t('rules.notice_title_min_length')
]

/**
 * thesis file rules validation
 * @param ctx
 * @returns {[(function(*=))]}
 */
export const thesis_title_filter = (ctx) => [
    v => !v || v.length >= limits.THESIS_TITLE_FILTER_MIN_LENGTH || ctx.$t('rules.thesis_title_filter_min'),
    v => !v || v.length <= limits.THESIS_TITLE_MAX_LENGTH || ctx.$t('rules.thesis_title_max')
]

/**
 * thesis author rules validation
 * @param ctx
 * @returns {[(function(*=))]}
 */
export const thesis_authors = (ctx) => [
    v => !v || v.split(',').length <= limits.MAX_AUTHORS || ctx.$t('rules.max_authors'),
    v => !v || v.split(',').every(author => author.trim().length <= limits.NAME_MAX_LENGTH + limits.SURNAME_MAX_LENGTH) || ctx.$t('rules.authors_max_length'),
    v => !v || v.split(',').every(author => author.trim().length >= limits.NAME_MIN_LENGTH + limits.SURNAME_MIN_LENGTH) || ctx.$t('rules.authors_min_length')
]

/**
 * thesis author rules validation
 * @param ctx
 * @returns {[(function(*=))]}
 */
export const thesis_adviser = (ctx) => [
    v => !v || v.split(',').length <= limits.MAX_ADVISERS || ctx.$t('rules.max_advisers'),
    v => !v || v.split(',').every(adviser => adviser.trim().length <= limits.NAME_MAX_LENGTH + limits.SURNAME_MAX_LENGTH) || ctx.$t('rules.advisers_max_length'),
    v => !v || v.split(',').every(adviser => adviser.trim().length >= limits.NAME_MIN_LENGTH + limits.SURNAME_MIN_LENGTH) || ctx.$t('rules.advisers_min_length')
]

/**
 * thesis adviser rules validation
 * @param ctx
 * @returns {[(function(*=))]}
 */
export const thesis_advisers = (ctx) => [
    v => !v || v.split(',').length <= limits.MAX_ADVISERS || ctx.$t('rules.max_authors'),
    v => !v || v.split(',').every(adviser => adviser.trim().length <= limits.NAME_MAX_LENGTH + limits.SURNAME_MAX_LENGTH) || ctx.$t('rules.advisers_max_length'),
    v => !v || v.split(',').every(adviser => adviser.trim().length >= limits.NAME_MIN_LENGTH + limits.SURNAME_MIN_LENGTH) || ctx.$t('rules.advisers_min_length')
]

/**
 * thesis keyword rules validation
 * @param ctx
 * @returns {[(function(*=))]}
 */
export const thesis_keywords = (ctx) => [
    v => !v || v.split(',').length <= limits.MAX_KEYWORDS || ctx.$t('rules.max_keywords'),
    v => !v || v.split(',').every(keyword => keyword.trim().length > 0) || ctx.$t('rules.keywords_min_length'),
    v => !v || v.split(',').every(keyword => keyword.trim().length <= limits.KEYWORD_MAX_LENGTH) || ctx.$t('rules.keywords_max_length')
]

/**
 * thesis author rules validation
 * @param ctx
 * @returns {[(function(*=))]}
 */
export const thesis_year = (ctx) => [
    v => !!v || ctx.$t('rules.thesis_year_required'),
    v => (!!v && !Number.isNaN(v)) || ctx.$t('rules.integer_required'),
    v => (!!v && Number.isInteger(parseInt(v))) || ctx.$t('rules.integer_required'),
    v => (!!v && v < new Date().getFullYear() + 1) || ctx.$t('rules.thesis_year_max')
]

/**
 * thesis title rules validation
 * @param ctx
 * @returns {[(function(*=)), (function(*)), (function(*))]}
 */
export const thesis_title  = (ctx) => [
    v => !!v || ctx.$t('rules.thesis_title_required'),
    v => (!!v && v.length >= limits.THESIS_TITLE_MIN_LENGTH) || ctx.$t('rules.thesis_title_min'),
    v => (!!v && v.length <= limits.THESIS_TITLE_MAX_LENGTH) || ctx.$t('rules.thesis_title_max')
];

/**
 * thesis title rules validation
 * @param ctx
 * @returns {[(function(*))]}
 */
export const thesis_uri  = (ctx) => [
    v => (!!v && v.length <= limits.THESIS_URI_MAX_LENGTH) || ctx.$t('rules.thesis_uri_max')
];

/**
 * thesis title rules validation
 * @param ctx
 * @returns {[(function(*=)), (function(*)), (function(*))]}
 */
export const thesis_abstract  = (ctx) => [
    v => (!!v && v.length <= limits.MAX_TEXT_LENGTH) || ctx.$t('rules.thesis_abstract_max_size')
];

/**
 * thesis title rules validation
 * @param ctx
 * @returns {[(function(*=)), (function(*)), (function(*))]}
 */
export const user_filter_name  = (ctx) => [
    v => (!v || v.length >= limits.NAME_MIN_LENGTH)|| ctx.$t('rules.name_min'),
    v => (!v || v.length <= limits.NAME_MAX_LENGTH)|| ctx.$t('rules.name_max'),
];

/**
 * thesis title rules validation
 * @param ctx
 * @returns {[(function(*=)), (function(*)), (function(*))]}
 */
export const user_filter_surname  = (ctx) => [
    v => (!v || v.length >= limits.SURNAME_MIN_LENGTH)|| ctx.$t('rules.surname_min'),
    v => (!v || v.length <= limits.SURNAME_MAX_LENGTH)|| ctx.$t('rules.surname_max'),
];

/**
 * thesis title rules validation
 * @param ctx
 * @returns {[(function(*=)), (function(*)), (function(*))]}
 */
export const user_filter_email  = (ctx) => [
    v => (!v || v.length <= limits.EMAIL_MAX_LENGTH)|| ctx.$t('rules.email_max'),
];