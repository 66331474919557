import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import i18n from '@/i18n'
import dotenv from 'dotenv';
import styles from '@/App.scss'

dotenv.config();

Vue.config.productionTip = false;

// TODO: erase api prefix forcing in login, notices and axios

new Vue({
  router,
  store,
  vuetify,
  styles,
  i18n,
  render: h => h(App)
}).$mount('#app');
