import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from "vuetify/lib/util/colors";
import '@fortawesome/fontawesome-free/css/all.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        dark: false,
        themes: {
            light : {
                primary: "#5A1236",
                secondary: "#5B262E",
                accent: "#333",
                background:"#F1F1F1",
                error: colors.red.darken2,
                info: colors.grey.darken1,
                success: colors.green.darken1,
                warning: "#ff9966"
            },
            dark : {
                primary: "#32587A",
                secondary: "#677C88",
                accent: "#B54E2F",
                background: "#333",
                error: "#B72828",
                info: colors.grey.darken1,
                success: colors.green.darken1,
                warning: "#D2B938"
            }
        }
    },
    icons: {
        iconfont: 'md',
    }
});
