'use strict';
import jwtDecode from 'jwt-decode';
import {root} from "../../../.eslintrc";

/**
 * @type {{payload: {}, JWT: string}}
 */
export const state = {
    payload: {},
    JWT: '',
};

export const actions = {
    setJWT: ({commit, dispatch}, jwt) => {
        commit('SET_JWT', jwt);
        dispatch('Token/setPayload', jwtDecode(jwt), {root: true});
    },
    destroyJWT: ({commit, dispatch}) => {
        commit('Token/DESTROY_JWT', {}, {root: true});
        dispatch('Token/deletePayload', {}, {root: true});
    },
    /**
     * @param commit
     * @param payload {JSON}
     */
    setPayload: ({commit}, payload) => {
        commit('SET_PAYLOAD', payload);
    },
    /**
     * @param commit
     * @param property {{key: string, value: *}}
     */
    changePayloadProperty: ({commit}, property) => {
        commit('CHANGE_PAYLOAD_PROPERTY', property);
    },
    /**
     * @param commit
     * @param prop {string}
     */
    removePayloadProperty: ({commit}, prop) => {
        commit('REMOVE_PAYLOAD_PROPERTY', prop);
    },
    /**
     * Sets the payload to an uninitialized JSON
     * @param commit
     */
    deletePayload: ({commit}) => {
        commit('DELETE_PAYLOAD');
    }
}

export const mutations = {
    /**
     * @param state
     * @param jwt {string}
     */
    SET_JWT: (state, jwt) => {
        state.JWT = jwt;
    },
    /**
     * @param state
     */
    DESTROY_JWT: (state) => {
        state.JWT = '';
    },
    /**
     * @param state
     * @param payload {JSON}
     */
    SET_PAYLOAD: (state, payload) => {
        state.payload = payload;
    },
    /**
     * @param state
     * @param property {{key: string, value: *}}
     */
    CHANGE_PAYLOAD_PROPERTY: (state, property) => {
        state.payload[property.key] = property.value;
    },
    /**
     * @param state
     * @param prop {string}
     * @constructor
     */
    REMOVE_PAYLOAD_PROPERTY: (state, prop) => {
        delete state.payload[prop];
    },
    /**
     * Sets the payload to an uninitialized JSON
     * @param state
     * @constructor
     */
    DELETE_PAYLOAD: (state) => {
        state.payload = {};
    }
};