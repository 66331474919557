'use strict';
import { v4 as uuidV4 } from 'uuid';

export const state = {
    alerts: []
};

export const actions = {
    addAlert: ({commit}, alert) => { // eslint-disable-line no-unused-vars
        for (let key of Object.keys(alert.properties))
            alert[key] = alert.properties[key];
        commit('PUSH_ALERT', alert);
    },
    showAlert: ({commit, dispatch}, alert) => { // eslint-disable-line no-unused-vars
        dispatch('CustomAlert/addAlert', {
            properties: alert
        }, {root: true});
    },
    removeAlert: ({commit}, alert) => {
        commit('REMOVE_ALERT', alert);
    }
}

export const mutations = {
    PUSH_ALERT: (state, alert) => {
        state.alerts.push({
            ...alert,
            id: uuidV4()
        });
    },
    REMOVE_ALERT: (state, alert2remove) => {
        state.alerts = state.alerts.filter(alert => alert.id !== alert2remove.id)
    }
};