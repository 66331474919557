import {HTTP_AUTH} from "@/plugins/axios-http-common";
import {axiosResponseError} from "@/utils/HTTP_handlers";

export const getSchools = (rol, JWT, ctx) => HTTP_AUTH(JWT).get(`/${rol}/get-schools`)
	.then(response =>{
		if (response.status === 200) {
			return response.data;
		}
	}).catch(err => {
		axiosResponseError(ctx, err, {
			status: 428,
			type: 'info',
			msg: ctx.$t('alerts.error.load_failed')
		});
	});

export const getCareers = (rol, JWT, ctx) => HTTP_AUTH(JWT).get(`${rol}/get-careers`)
	.then((response) => {
		if ( response.status === 200 )
			return  response.data;
	}).catch(err => {
		axiosResponseError(ctx, err, {
			status: 428,
			type: 'info',
			msg: ctx.$t('alerts.error.load_failed')
		});
	});
