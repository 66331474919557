<template>
	<v-card>
		<v-card-title v-text="title" style="word-break: keep-all"></v-card-title>
		<v-card-subtitle >
			<p class="font-weight-bold" v-if="specialization">
				{{ career }} - {{specialization}} {{year}}
			</p>
			<p class="font-weight-bold" v-else>
				{{ career }} - {{year}}
			</p>
			<div v-if="keywords && keywords.length">
          <span class="font-weight-bold">
            {{$t('components.common.thesis.keywords') }}:
          </span>
				{{getKeywords.join(', ')}}
			</div>
		</v-card-subtitle>
		<v-slide-y-transition>
			<v-card-text v-show="show_more">
				<v-textarea
					:value="abstract"
					:label="$t('components.common.thesis.abstract')"
					readonly
					no-resize
					class="px-4"
				>
				</v-textarea>
				<v-row>
					<v-col
						cols="12"
						sm="12"
						md="6"
					>
						<div v-if="authors && authors.length">
							<p
								v-text="$t('components.common.thesis.authors')"
								class="text--primary"
							></p>
							<transition-group name="flip-list" tag="ul">
								<li v-for="(author) in authors"
									v-bind:key="author.full_name + Math.random().toString(16)"
									class="text--secondary"
								>
									{{ author.full_name }}
								</li>
							</transition-group>
						</div>
					</v-col>
					<v-col
						cols="12"
						sm="12"
						md="6"
					>
						<div v-if="advisers && advisers.length">
							<p
								v-text="$tc('components.common.thesis.adviser', advisers.length > 1 ? 0 : 1)"
								class="text--primary"
							></p>
							<transition-group name="flip-list" tag="ul">
								<li v-for="(adviser) in advisers"
									v-bind:key="adviser.full_name + Math.random().toString(16)"
									class="text--secondary"
								>
									{{ adviser.full_name }}
								</li>
							</transition-group>
						</div>
					</v-col>
				</v-row>
			</v-card-text>
		</v-slide-y-transition>
		<v-spacer></v-spacer>
		<v-card-actions>
			<v-btn
				color="accent"
				icon
				v-if="this.payload.rol === 'admin' || this.payload.rol === 'teacher'"
				@click="delete_confirmation = true"
			>
				<v-icon dark>delete</v-icon>
			</v-btn>
			<v-btn
				color="accent"
				icon
				v-if="this.payload.rol === 'admin' || this.payload.rol === 'teacher'"
				:to="{ path: `/${payload.rol}/edit-thesis`, query: { thesis_id }}"
			>
				<v-icon dark>edit</v-icon>
			</v-btn>
			<v-spacer></v-spacer>
			<v-btn
				color="primary"
				text
				@click="show_more = !show_more"
				bottom
			>
				<span v-if="show_more">{{ $t('components.common.thesis.show_less') }}</span>
				<span v-else>{{ $t('components.common.thesis.show_more') }}</span>
			</v-btn>
			<v-btn
				color="primary"
				:disabled="!thesis_uri"
				text
				@click="downloadFile"
				bottom
				v-t="'components.common.thesis.visit'"
			>
			</v-btn>
		</v-card-actions>
		<v-dialog
			v-if="this.payload.rol === 'admin' || this.payload.rol === 'teacher'"
			v-model="delete_confirmation"
			max-width="720px"
		>
			<v-card>
				<v-card-title
					class="text-h5"
					v-t="'components.common.thesis.delete_title'"
				></v-card-title>
				<v-card-text
					v-t="'components.common.thesis.delete_info'"
				></v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="error"
						text
						@click="deleteThesis"
						v-t="'common_labels.delete'"
					>
					</v-btn>

					<v-btn
						color="primary"
						text
						@click="delete_confirmation = false"
						v-t="'common_labels.cancel'"
					>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>

import { HTTP_AUTH } from '@/plugins/axios-http-common';
import { axiosResponseError } from '@/utils/HTTP_handlers';
import { mapActions, mapState } from 'vuex';

export default {
	props: {
		abstract: {
			type: String,
			required: true
		},
		thesis_id: {
			type: Number,
			required: true
		},
		school_id: {
			type: Number,
			required: true
		},
		advisers: {
			type: Array,
			/**
			 * @typedef author
			 * @type {Object}
			 * @property {number} thesis_id
			 * @property {string} full_name
			 *
			 * @param values {array<author>}
			 * @returns {*}
			 */
			validator: (values) => {
				return values.every(value =>
					typeof value.full_name === "string"
				)
			},
			required: false
		},
		authors: {
			type: Array,
			/**
			 * @typedef author
			 * @type {Object}
			 * @property {number} thesis_id
			 * @property {string} full_name
			 *
			 * @param values {array<author>}
			 * @returns {*}
			 */
			validator: (values) => {
				return values.every(value =>
					typeof value.full_name === "string"
				)
			},
			required: false
		},
		keywords: {
			type: Array,
			/**
			 * @typedef author
			 * @type {Object}
			 * @property {number} thesis_id
			 * @property {string} keyword
			 *
			 * @param values {array<author>}
			 * @returns {*}
			 */
			validator: (values) => {
				return values.every(value =>
					typeof value.keyword === "string"
				)
			},
			required: false
		},
		career: {
			type: String,
			required: true
		},
		specialization: {
			type: String,
			required: false
		},
		title: {
			type: String,
			required: true
		},
		thesis_uri: {
			type: String,
			required: false
		},
		year: {
			type: Number,
			required: true
		},
	},
	data () {
		return {
			show_more: false,
			delete_confirmation: false
		}
	},
	methods: {
		...mapActions('CustomAlert', ['showAlert']),
		downloadFile() {
			HTTP_AUTH(this.JWT).get(`${this.payload.rol}/download-thesis`, {
				params: {
					token: this.JWT,
					thesis_id: this.thesis_id,
					school_id: this.school_id
				},
			}).then(response => {
				const thesis_uri = response.data.thesis_uri;
				window.open(thesis_uri, '_blank');
			}).catch(error =>
				axiosResponseError(this, error)
			);
		},
		deleteThesis() {
			this.delete_confirmation = false;
			HTTP_AUTH(this.JWT).post(`${this.payload.rol}/delete-thesis`, {
				thesis_id: this.thesis_id,
				school_id: this.school_id
			}).then(response => {
				if (response.status === 200) {
					this.showAlert({text: this.$t('components.common.thesis.deleted'), type: 'success', icon: 'check_circle'});
					this.$emit('deleted-thesis');
				}
			}).catch(error =>
				axiosResponseError(this, error)
			);
		}
	},
	computed: {
		...mapState('Token', ['JWT', 'payload']),
		/**
		 * Return all the keywords as string array
		 * @returns {array<string>}
		 */
		getKeywords() {
			const n = this.keywords.length;
			let keywords = new Array(this.keywords.length);
			for (let i = 0; i < n; i++)
				keywords[i] = this.keywords[i].keyword;
			return keywords;
		}
	}
}
</script>

<style scoped>

.slide-fade-enter-active {
	transition: all 2s ease;
}

</style>