'use strict';

export const state = {
    password: '',
    confirm_password_dialog: false
};

export const actions = {
    hideConfirmPassword: ({commit}) => {
        commit('HIDE_CONFIRM_PASSWORD');
    },
    removePassword: ({commit}, password) => {
        commit('REMOVE_PASSWORD', password);
    },
    setPassword: ({commit}, password) => {
        commit('SET_PASSWORD', password);
    },
    showConfirmPassword: ({commit}) => {
        commit('SHOW_CONFIRM_PASSWORD');
    }
}

export const mutations = {
    SET_PASSWORD: (state, password) => {
        state.password = password;
        state.confirm_password_dialog = false;
    },
    REMOVE_PASSWORD: (state) => {
        state.password = '';
    },
    HIDE_CONFIRM_PASSWORD: (state) => {
        state.confirm_password_dialog = false;
    },
    SHOW_CONFIRM_PASSWORD: (state) => {
        state.confirm_password_dialog = true;
    }
};