import axios from 'axios';
import dotenv from 'dotenv';
const path = require('path');

dotenv.config({path: path.join(__dirname, '../.env')});

const base_url = `${process.env.VUE_APP_SERVER_HOST}${process.env.VUE_APP_SERVER_PORT ? `:${process.env.VUE_APP_SERVER_PORT}` : ''}/${process.env.VUE_APP_API_PREFIX || ''}`;

const HTTP = () => {
	return axios.create({
		baseURL: base_url,
		headers: {
			'Content-type': 'application/json; charset=UTF-8'
		}
	});
}
/**
 *
 * @param JWT {string} - JSONWebToken
 * @returns {AxiosInstance}
 * @constructor
 */
const HTTP_AUTH = (JWT) => {
	return axios.create({
		baseURL: base_url,
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
			'Authentication': `Bearer ${JWT}`
		}
	});
}

/**
 *
 * @param JWT {string} - JSONWebToken
 * @returns {AxiosInstance}
 * @constructor
 */
const HTTP_FORM_DATA_AUTH = (JWT) => {
	return axios.create({
		baseURL: base_url,
		headers: {
			'Content-type': 'multipart/form-data; charset=UTF-8',
			'Authentication': `Bearer ${JWT}`
		}
	});
}

export {
	HTTP,
	HTTP_AUTH,
	HTTP_FORM_DATA_AUTH
}