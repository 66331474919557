import * as regex from './regex'
import { limits } from '@/utils/validations/limits';


export const schemas = {
    update_password : {
        $async: true,
        type: 'object',
        required: [
            'new_password',
            'old_password'
        ],
        properties: {
            old_password: {
                type: 'string',
                maxLength: limits.PASSWORD_MAX_LENGTH,
                minLength: limits.PASSWORD_MIN_LENGTH,
            },
            new_password: {
                type: 'string',
                maxLength: limits.PASSWORD_MAX_LENGTH,
                minLength: limits.PASSWORD_MIN_LENGTH,
            }
        }
    },

}

export const recover_password_schema = {
    $async: true,
    type: 'object',
    required: [
        'email',
        'rol'
    ],
    properties: {
        email: {
            type: 'string',
            minLength: limits.EMAIL_MIN_LENGTH,
            maxLength: limits.EMAIL_MAX_LENGTH,
            format: 'email'
        },
        rol: {
            type: 'string',
            maxLength: 16,
            minLength: 5
        }
    }
};

export const register_thesis_schema = {
    $async: true,
    type: 'object',
    required: [
        'career_id',
        'title',
        'year',
        'abstract'
    ],
        properties: {
        career_id: {
            type: 'integer',
            maximum: limits.MAX_INT_UNSIGNED,
            minimum: 1
        },
        specialization_id: {
            type: 'integer',
            maximum: limits.MAX_INT_UNSIGNED,
            minimum: 1
        },
        title: {
            type: 'string',
            maxLength: limits.THESIS_TITLE_MAX_LENGTH,
            minLength: limits.THESIS_TITLE_MIN_LENGTH
        },
        year: {
            type: 'integer',
            maximum: limits.MAX_SMALLINT_UNSIGNED,
            minimum: 0
        },
        abstract: {
            type: 'string',
            maxLength: limits.MAX_TEXT_LENGTH
        }
    }
}

export const get_thesis_filters = {
    $async: true,
    type: 'object',
    properties: {
        year: {
            type: 'integer',
            maximum: limits.MAX_SMALLINT_UNSIGNED,
            minimum: 0,
        },
        title: {
            type: 'string',
            maxLength: limits.THESIS_TITLE_MAX_LENGTH,
            minLength: limits.THESIS_TITLE_FILTER_MIN_LENGTH
        },
        keywords : {
            type: 'array',
            uniqueItems: true,
            maxItems: limits.MAX_KEYWORDS,
            items: {
                type: 'string',
                maxLength: limits.NAME_MAX_LENGTH + limits.SURNAME_MAX_LENGTH,
                minLength: 1
            }
        },
        authors : {
            type: 'array',
            uniqueItems: true,
            maxItems: limits.MAX_AUTHORS,
            items: {
                type: 'string',
                maxLength: limits.NAME_MAX_LENGTH + limits.SURNAME_MAX_LENGTH,
                minLength: limits.NAME_MIN_LENGTH + limits.SURNAME_MIN_LENGTH
            }
        },
        careers: {
            type: 'array',
            items: {
                type: 'object',
                required: [
                    'id'
                ],
                properties: {
                    id: {
                        type: 'integer',
                        maximum: limits.MAX_INT_UNSIGNED,
                        minimum: 1
                    },
                    specializations: {
                        type: 'array',
                        items: {
                            type: 'integer',
                            maximum: limits.MAX_INT_UNSIGNED,
                            minimum: 1
                        }
                    }
                }
            }
        }
    }
}

export const register_notice_schema = {
    $async: true,
    type: 'object',
    required: [
        'title',
        'info',
    ],
    properties: {
        title: {
            type: 'string',
            minLength: limits.NOTICE_TITLE_MIN_LENGTH,
            maxLength: limits.NOTICE_TITLE_MAX_LENGTH
        },
        info: {
            type: 'string',
            maxLength: limits.MAX_TEXT_LENGTH,
            minLength: limits.NOTICE_INFO_MIN_LENGTH
        },
        contact: {
            type: ['string', 'null'],
            maxLength: limits.NOTICE_CONTACT_MAX_LENGTH,
            minLength: limits.EMAIL_MIN_LENGTH,
        },
        expiration: {
            type: ['string', 'null'],
            format: 'date-time',
            formatMinimum: new Date(new Date().getTime() + 1000 * 60 * 60 * 12).toISOString()
        }
    }
}

export const login_schema = {
    $async: true,
    type: 'object',
    required: [
        'email',
        'password',
        'rol'
    ],
    properties: {
        email: {
            type: 'string',
            minLength: limits.EMAIL_MIN_LENGTH,
            maxLength: limits.EMAIL_MAX_LENGTH,
            format: 'email'
        },
        password: {
            type: 'string',
            maxLength: limits.PASSWORD_MAX_LENGTH,
            minLength: limits.PASSWORD_MIN_LENGTH,
        },
        rol: {
            type: 'string',
            maxLength: 16,
            minLength: 5
        }
    }
};

export const register_user_schema = {
    $async: true,
    type: 'object',
    required: [
        'email',
        'name',
        'surname'
    ],
    properties: {
        email: {
            type: 'string',
            minLength: limits.EMAIL_MIN_LENGTH,
            maxLength: limits.EMAIL_MAX_LENGTH,
            format: 'email'
        },
        name: {
            type: 'string',
            maxLength: limits.NAME_MAX_LENGTH,
            minLength: limits.NAME_MIN_LENGTH,
            pattern: regex.ALPHA_W_SPACES.source
        },
        surname: {
            type: 'string',
            maxLength: limits.SURNAME_MAX_LENGTH,
            minLength: limits.SURNAME_MIN_LENGTH,
            pattern: regex.ALPHA_W_SPACES.source
        }
    }
}