<template>
	<v-app id="app">
		<router-view />
		<AlertList style="z-index: 999" />
	</v-app>
</template>

<script>

import AlertList from "@/components/common/Alerts/AlertList";

export default {
	name: 'App',
	components: { AlertList }
};
</script>