<template>
  <v-layout column class="px-md-6 px-sm-1">
    <v-alert
        dismissible
        close-icon="delete"
        border="left"
        elevation="6"
        colored-border
        rounded="rounded"
        :icon="alert.icon"
        :type="alert.type"
    >
      <span v-text="alert.text"></span>
    </v-alert>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Alert',
  props: ['alert'],
  data() {
    return {
      timeout: null
    }
  },
  created() {
    setTimeout(()=> {
      this.removeAlert(this.alert);
    }, 5000);
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  methods: {
    ...mapActions('CustomAlert',['removeAlert'])
  }
}
</script>
