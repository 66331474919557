export const limits = {
    EMAIL_MAX_LENGTH : 127,
    EMAIL_MIN_LENGTH : 5,
    KEYWORD_MAX_LENGTH : 32,
    MAX_TEXT_LENGTH: 65535,
    MAX_AUTHORS: 6,
    MAX_ADVISERS: 3,
    MAX_KEYWORDS: 10,
    NAME_MIN_LENGTH : 2,
    NAME_MAX_LENGTH : 32,
    NOTICE_MAX_FILES : 5,
    NOTICE_MAX_IMAGES : 5,
    NOTICE_FILE_MAX_SIZE: 10000000, // 10 MB
    NOTICE_CONTACT_MAX_LENGTH : 127,
    NOTICE_INFO_MIN_LENGTH : 20,
    NOTICE_TITLE_MAX_LENGTH : 127,
    NOTICE_TITLE_MIN_LENGTH : 5,
    SURNAME_MIN_LENGTH : 2,
    SURNAME_MAX_LENGTH : 64,
    PASSWORD_MAX_LENGTH : 64,
    PASSWORD_MIN_LENGTH : 6,
    PHOTO_MAX_SIZE: 5000000, // 5 MB
    THESIS_FILE_MAX_SIZE: 50000000, // 50 MB
    THESIS_TITLE_MAX_LENGTH: 255,
    THESIS_TITLE_MIN_LENGTH: 12,
    THESIS_TITLE_FILTER_MIN_LENGTH: 5,
    THESIS_URI_MAX_LENGTH: 255
}