import Vue from 'vue';
import VueRouter from 'vue-router';

import Thesis from '@/components/common/Thesis/Thesis';

import store from '@/store/index'
import { actions } from '@/store/modules/token'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('@/views/Surfer'),
    children: [
      {
        path: '/',
        name: 'Login',
        component: () => import('@/components/Login/Login')
      },
      {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import('@/components/Login/ForgotPassword'),
        props: route => ({ rol: route.query.rol })
      },
      {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('@/components/Login/ResetPassword'),
        props: route => ({ token: route.query.token })
      },
      {
        path: 'admin/login',
        name: 'LoginAdmin',
        component: () => import('@/components/Login/AdminLogin')
      },
    ]
  },
  {
    path: '/admin',
    component: ()=> import('@/views/Admin'),
    children: [
      {
        path: '/',
        name: 'AdminHome',
        component: Thesis
      },
      {
        path: 'student',
        name: 'AdminStudents',
        component: () => import('@/components/common/Student/Student')
      },
      {
        path: 'teacher',
        name: 'AdminTeacher',
        component: () => import('@/components/admin/Teacher')
      },
      {
        path: 'account',
        name: 'AdminAccount',
        component: () => import('@/components/common/Account/Account')
      },
      {
        path: 'notices',
        name: 'AdminNotices',
        component: () => import('@/components/common/Notices/Notices')
      },
      {
        path: 'thesis',
        component: Thesis
      },
      {
        path: 'edit-thesis',
        component: () => import('@/components/common/Thesis/EditThesis'),
        props: route => ({ thesis_id: route.query.thesis_id })
      },
    ]
  },
  {
    path: '/student',
    component: ()=> import('@/views/Student'),
    children: [
      {
        path: '/',
        name: 'StudentHome',
        component: () => import('@/components/common/Thesis/Thesis')
      },
      {
        path: 'notices',
        name: 'StudentNotices',
        component: () => import('@/components/common/Notices/Notices')
      },
      {
        path: 'account',
        name: 'StudentAccount',
        component: () => import('@/components/common/Account/Account')
      }
    ]
  },
  {
    path: '/teacher',
    component: ()=> import('@/views/Teacher'),
    children: [
      {
        path: '/',
        name: 'TeacherHome',
        component: () => import('@/components/common/Thesis/Thesis')
      },
      {
        path: 'notices',
        name: 'TeacherNotices',
        component: () => import('@/components/common/Notices/Notices')
      },
      {
        path: 'student',
        name: 'TeacherStudents',
        component: () => import('@/components/common/Student/Student')
      },
      {
        path: 'account',
        name: 'TeacherAccount',
        component: () => import('@/components/common/Account/Account')
      },
      {
        path: 'edit-thesis',
        component: () => import('@/components/common/Thesis/EditThesis'),
        props: route => ({ thesis_id: route.query.thesis_id })
      },
    ]
  },
  {
    path: '*',
    component: () => {
      let jwt = store.state.Token.payload;
      if (!jwt.rol)
        return import('@/views/Surfer')

      switch (jwt.rol) {
        case 'admin':
          return import('@/views/Admin');
        case 'student':
          return import('@/views/Student');
        default:
          return import('@/views/Surfer');
      }
    },
    children: [
      {
        path: '/403',
        component: () => import('@/components/common/HTTP-errors/Error403')
      },
      {
        path: '/',
        component: () => import('@/components/common/HTTP-errors/Error404')
      },
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

const privatePages = [/^\/admin/, /^\/student/, /^\/teacher/];
const loginPages = [
  /^\/$/,
  /^\/admin\/login$/
];

router.beforeEach((to, from, next) => {
  const authRequired = privatePages.some(page => page.test(to.path));
  const login = loginPages.some(page => page.test(to.path));
  const payload = store.state.Token.payload;
  const expired = payload.exp < new Date().getTime()/1000;
  if(expired)
    actions.destroyJWT({commit: store.commit, dispatch: store.dispatch});

  const token = store.state.Token.JWT;

  if (login) {
    if(token)
      return next(`${payload.rol}`)
    return next();
  }

  if (!authRequired)
    return next();

  if (authRequired && !token)
    return next(/^\/admin/.test(to.path) ? '/admin/login' : '/');

  if (authRequired && payload.rol !== to.path.split('/')[1])
    return next(`/403`);

  next();
});

export default router