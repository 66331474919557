<template>
	<v-container fill-height fluid>
		<v-tooltip left>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					fab
					small
					right
					fixed
					top
					v-bind="attrs"
					v-on="on"
					@click="show_filters = !show_filters"
					style="margin-top: 60px"
				>
					<v-icon>
						filter_alt
					</v-icon>
				</v-btn>
			</template>
			<span>{{$tc('components.common.thesis.show_filters', show_filters ? 0 : 1)}}</span>
		</v-tooltip>
		<v-slide-y-transition>
			<v-card
				width="100%"
				v-show="show_filters"
				class="mt-3 mb-6"
				@keydown.enter="applyFilters"
			>
				<v-card-title
					v-t="'components.common.thesis.search_filters'"
				>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-spacer></v-spacer>
						<v-btn
							icon
							@click.stop="show_info = true"
						>
							<v-icon>help</v-icon>
						</v-btn>
					</v-row>
					<v-form
						ref="filters"
					>
						<v-row class="px-4" >
							<v-col cols="12"
								sm="12"
								md="8"
							>
								<v-text-field
									dense
									v-model="filters.title"
									prepend-icon="search"
									:rules="rules.thesis_title_filter"
									:label="$t('components.common.thesis.filter_title')"
								/>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="4"
							>
								<v-text-field
									clearable
									dense
									v-model="filters.year"
									:label="$t('components.common.thesis.filter_year')"
									:max="new Date().getFullYear() + 1"
									:min="min_year"
									type="number"
								/>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="4"
							>
								<v-text-field
									clearable
									dense
									v-model="filters.keywords"
									:rules="rules.thesis_keywords_filter"
									:label="$t('components.common.thesis.filter_keywords')"
									:hint="$t('components.common.thesis.hint_keywords_comma_separated')"
								/>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="4"
							>
								<v-text-field
									clearable
									dense
									v-model="filters.authors"
									:rules="rules.thesis_authors_filter"
									:label="$t('components.common.thesis.filter_authors')"
									:hint="$t('components.common.thesis.hint_authors_comma_separated')"
								/>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="4"
							>
								<v-text-field
									clearable
									dense
									v-model="filters.advisers"
									:rules="rules.thesis_authors_filter"
									:label="$t('components.common.thesis.filter_advisers')"
									:hint="$t('components.common.thesis.hint_advisers_comma_separated')"
								/>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="4"
							>
								<v-autocomplete
									dense
									:disabled="this.schools ? this.schools.length < 2 : true"
									:items="schools"
									item-text="school"
									item-value="school_id"
									v-model="filters.school_id"
									:no-data-text="$t('components.common.thesis.no_data_career')"
									:label="$tc('components.common.thesis.filter_school')"
									@change="establishSchoolFilter"
								>
								</v-autocomplete>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="4"
							>
								<v-autocomplete
									dense
									ref="select_career_filter"
									:items="select_careers"
									item-text="career"
									item-value="id"
									multiple
									:no-data-text="$t('components.common.thesis.no_data_career')"
									:label="$tc('components.common.thesis.career', filters.careers.length)"
									@change="establishCareerFilters($event)"
								>
								</v-autocomplete>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="4"
							>
								<v-autocomplete
									dense
									:items="select_specializations"
									ref="select_specialization_filter"
									item-text="specialization"
									return-object
									clearable
									multiple
									:label="$tc('components.common.thesis.specialization', specializations_count)"
									:no-data-text="filters.careers.length ?
                      $t('components.common.thesis.no_specializations') :
                      $t('components.common.thesis.no_data_specialization')"
									@change="establishSpecializationFilters($event)"
								>
								</v-autocomplete>
							</v-col>
							<v-col cols="12"
							       sm="12"
							       md="8"
							>
								<v-radio-group
									dense
									row
									v-model="filters.thesis_uri"
								>
									<v-radio
										:label="$t('components.common.thesis.filter_uri_required')"
										:value="true"
										color="accent"
									/>
									<v-radio
										:label="$t('components.common.thesis.filter_uri_lack')"
										:value="false"
										color="accent"
									/>
								</v-radio-group>

							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						class="text--secondary"
						v-t="'components.common.thesis.remove_filters'"
						@click="removeFilters"
					>
					</v-btn>
					<v-btn
						text
						color="primary"
						v-t="'components.common.thesis.apply_filters'"
						@click="applyFilters"
					>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-slide-y-transition>

		<v-container
			v-if="!schools"
			fluid
		>
			<v-card>
				<v-card-title
					class="justify-center"
					v-t="'alerts.warnings.no_schools'"
				></v-card-title>
			</v-card>
		</v-container>

		<v-row class="px-4">
			<v-col
				cols="12"
				sm="12"
				md="6"
				xl="4"
				v-for="(thesis, idx) in thesis" :key="idx"
				class="px-md-6 px-lg-12 d-flex"
			>
				<v-slide-y-transition>
					<ThesisCard
						:abstract="thesis.abstract"
						:authors="thesis.authors"
						:advisers="thesis.advisers"
						:thesis_uri="thesis.thesis_uri"
						:keywords="thesis.keywords"
						:career="thesis.career"
						:specialization="thesis.specialization"
						:title="thesis.title"
						:thesis_id="thesis.thesis_id"
						:school_id="thesis.school_id"
						:year="thesis.year"
						class="d-flex flex-column flex-grow-1"
						v-on:deleted-thesis="deleteThesisCard(idx)"
					>
					</ThesisCard>
				</v-slide-y-transition>
			</v-col>
		</v-row>

		<RegisterThesis
			v-if="this.payload.rol === 'admin' || this.payload.rol === 'teacher'"
			v-bind:careers="careers"
			v-on:registered-thesis="updateThesisList()"
		></RegisterThesis>
		<v-btn
			v-if="this.payload.rol === 'admin' || this.payload.rol === 'teacher'"
			fab dark
			class="accent"
			fixed right bottom
			@click="showForm"
		>
			<v-icon dark>add</v-icon>
		</v-btn>
		<v-row justify="center">
			<v-dialog
				v-model="show_info"
			>
				<v-card>
					<v-card-title class="text-h5" v-t="'components.common.thesis.info_title'">
					</v-card-title>

					<v-card-text>
						<ul>
							<li v-for="(item, idx) in $t('components.common.thesis.info_body')"
								v-bind:key="idx"
							>
								{{item}}
							</li>
						</ul>
					</v-card-text>

					<v-card-actions>
						<v-spacer></v-spacer>

						<v-btn
							text
							color="accent"
							v-t="'common_labels.close'"
							@click="show_info = false"
						>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-row>
	</v-container>
</template>

<script>

import { HTTP_AUTH } from '@/plugins/axios-http-common';
import { infiniteScroll } from '@/utils/common_actions';
import { axiosResponseError } from '@/utils/HTTP_handlers';
import { mapActions, mapState } from 'vuex';
import { ajv, ValidationError } from '@/utils/common_actions';
import {get_thesis_filters} from '@/utils/validations/json_schemas';
import RegisterThesis from '@/components/common/Thesis/RegisterThesis';
import ThesisCard from '@/components/common/Thesis/ThesisCard';
import {
	thesis_title_filter,
	thesis_keywords,
	thesis_authors,
	thesis_adviser
} from '@/utils/validations/rules';
import {getSchools} from "@/utils/repeated_actions";

export default {
	components:{
		ThesisCard,
		RegisterThesis
	},
	data() {
		return {
			apply_filters: false,
			show_info: false,
			info: '',
			careers: [],
			select_careers: [],
			specializations_count: 0,
			/**
			 * @type {
			 * [
			 *    {
			 *      career_id: number,
			 *      id: number,
			 *      specialization: string
			 *    }
			 *  ]
			 * }
			 *
			 */
			select_specializations: [],
			/**
			 * @typedef career_filter
			 * @type {Object}
			 * @property {number} id
			 * @property {array<number>} specializations
			 *
			 * @type {
			 *  {
			 *    careers: array<career_filter>,
			 *    title: string,
			 *    year: number,
			 *    keywords: string,
			 *    authors: string,
			 *    advisers: string
			 *  }
			 * }
			 *
			 */
			filters: {
				school_id: 0,
				careers: [],
				title: '',
				year: undefined,
				keywords: '',
				authors: '',
				advisers: '',
				thesis_uri: undefined
			},
			/**
			 * @typedef career_filter
			 * @type {Object}
			 * @property {number} id
			 * @property {array<number>} specializations
			 *
			 * @type {
			 *  {
			 *    careers: array<career_filter>|undefined,
			 *    title: string|undefined,
			 *    year: number|undefined,
			 *    keywords: string|undefined
			 *  }
			 * }
			 */
			search_filters: {},
			rules: {
				thesis_title_filter: thesis_title_filter(this),
				thesis_authors_filter: thesis_authors(this),
				thesis_advisers_filter: thesis_adviser(this),
				thesis_keywords_filter: thesis_keywords(this),
			},
			show_filters: true,
			/**
			 * @typedef author
			 * @type {Object}
			 * @property {number} thesis_id
			 * @property {string} name
			 * @property {string} surname
			 *
			 * @typedef advisers
			 * @type {Object}
			 * @property {number} thesis_id
			 * @property {string} name
			 * @property {string} surname
			 *
			 * @typedef thesis
			 * @type {Object}
			 * @property {string} abstract
			 * @property {array<author>} authors
			 * @property {array<advisers>} advisers
			 * @property {string} career
			 * @property {string} path
			 * @property {string} school
			 * @property {string} specialization
			 * @property {number} thesis_id - integer
			 * @property {string} title
			 * @property {year} number - integer
			 *
			 * @type array<thesis>
			 *
			 */
			thesis: [],
			schools: [],
			min_year: 0
		}
	},
	methods: {
		...mapActions('CustomAlert', ['showAlert']),
		...mapActions(['showForm']),
		/**
		 * This method removes from the dom the thesis with the specified id
		 * @param idx {number}
		 */
		deleteThesisCard(idx) {
			this.thesis.splice(idx, 1);
		},
		/**
		 * Set {@link apply_filters} to true,
		 * sets the {@link search_filters} with the
		 * values of {@link filters}
		 * resets the thesis array and
		 * calls {@link getThesis}
		 */
		async applyFilters() {
			this.search_filters = {
				school_id: this.filters.school_id ? this.filters.school_id : undefined,
				careers: this.filters.careers.length ? this.filters.careers : undefined,
				title: this.filters.title ? this.filters.title : undefined,
				year: isNaN(this.filters.year) || !this.filters.year ? undefined : parseInt(this.filters.year),
				keywords: this.filters.keywords ? this.filters.keywords : undefined,
				authors: this.filters.authors ? this.filters.authors : undefined,
				advisers: this.filters.advisers ? this.filters.advisers : undefined,
				thesis_uri: this.filters.thesis_uri
			}
			if (this.search_filters.keywords) {
				this.search_filters.keywords = this.search_filters.keywords
					.split(',').map(e => e.trim())
			}
			if (this.search_filters.authors) {
				this.search_filters.authors = this.search_filters.authors
					.split(',').map(e => e.trim())
			}

			if (this.search_filters.advisers) {
				this.search_filters.advisers = this.search_filters.advisers
					.split(',').map(e => e.trim())
			}

			try {
				await ajv.validate(get_thesis_filters, this.search_filters);
				this.thesis.splice(0, this.thesis.length);
				this.apply_filters = true;
				this.bottom = false;
			} catch (err) {
				if (err instanceof ValidationError)
					return this.showAlert({text: this.$t('alerts.warnings.incorrect_data'), type: 'warning', icon: 'warning'});
			}


			await this.getThesis();
		},
		/**
		 * Sets/removes career filters @see {@link this.filters.careers}
		 * Also sets/removes {@link this.select_specializations}
		 * @param event
		 */
		establishCareerFilters(event) {

			if (event.length < this.filters.careers.length) {
				this.filters.careers = this.filters.careers
					.filter(el => event.indexOf(el.id) !== -1);

				this.select_specializations = this.select_specializations
					.filter(specialization => event.indexOf(specialization.career_id) !== -1)
				return;
			}

			const new_career = event.find(element =>
				!this.filters.careers.some(career => career.id === element)
			);

			this.filters.careers.push({
				id: new_career,
				specializations: []
			});

			(this.select_careers.find(career => career.id === new_career)).specializations
				.forEach(specialization => this.select_specializations.push(
					{
						career_id: new_career,
						id: specialization.id,
						specialization: specialization.name
					}));
		},
		/**
		 * Sets/removes career.specialization filters
		 * @see {@link this.filters.careers.specializations}
		 * @param event
		 */
		establishSpecializationFilters(event) {
			for (const career of this.filters.careers) {
				career.specializations = event.filter(element => element.career_id === career.id)
					.map(specialization => specialization.id);
			}

			this.specializations_count = event.length;
		},
		/**
		 * establishes the careers
		 */
		async getCareers() {
			await HTTP_AUTH(this.JWT).get(`${this.payload.rol}/get-careers`).then((response) => {
				if ( response.status === 200 )
					this.careers = response.data;
			}).catch(e => {
				axiosResponseError(this, e);
			});
		},
		updateThesisList(){
			HTTP_AUTH(this.JWT).get(`${this.payload.rol}/get-thesis`,{
				params: {
					offset: 0,
					limit: 1,
					filters: this.apply_filters ? this.search_filters : undefined
				}
			}).then((response) => {
				if ( response.status === 200 ) {
					this.bottom = !response.data;
					if (this.bottom)
						return;
					response.data.forEach(thesis => {
						if (!this.thesis.some(e => e.thesis_id === thesis.thesis_id))
							this.thesis.unshift(thesis);
					});
				}
			}).catch(e => {
				axiosResponseError(this, e);
			});
		},
		/**
		 * Gets the next thesis with offset as {@link thesis}.length
		 * and optionally filters, based on {@link apply_filters}.
		 * If the last one it's set to true, then the filters are
		 * the same as {@link search_filters}, undefined otherwise
		 */
		async getThesis() {
			if (this.bottom)
				return;

			let filters = this.apply_filters ? this.search_filters : undefined;
			await HTTP_AUTH(this.JWT).get(`${this.payload.rol}/get-thesis`,{
				params: {
					offset: this.thesis.length,
					filters
				}
			}).then((response) => {
				if ( response.status === 200 ) {
					this.bottom = !response.data;
					if (this.bottom)
						return;
					response.data.forEach(thesis => {
						if (!this.thesis.some(e => e.thesis_id === thesis.thesis_id))
							this.thesis.push(thesis);
					});
				}
			}).catch(e => {
				axiosResponseError(this, e);
			});

		},
		/**
		 * Removes {@link filters},
		 * sets {@link search_filters} to {}
		 * and sets {@link apply_filters} to false
		 */
		removeFilters() {
			if (!this.schools) {
				return;
			}

			this.thesis.splice(0, this.thesis.length);
			this.$refs.filters.reset();
			this.apply_filters = false;
			this.search_filters = {};
			this.filters.careers = [];
			this.select_careers = [];
			this.filters.title = '';
			this.filters.year = undefined;
			this.bottom = false;
			this.select_specializations = [];
			this.filters.keywords = '';
			this.filters.authors = '';
			this.filters.thesis_uri = undefined;
			this.getThesis();

			if (this.schools.length === 1) {
				this.filters.school_id = this.schools[0].school_id;
				this.establishSchoolFilter();
			}
		},
		establishSchoolFilter() {
			this.filters.careers = [];
			this.select_specializations = [];
			this.$refs.select_career_filter.reset();
			this.$refs.select_specialization_filter.reset();
			this.select_careers = this.careers.filter(c => c.school_id === this.filters.school_id);
			this.min_year = new Date(
				(this.schools
						.find(s => s.school_id === this.filters.school_id)
				)['school_foundation']).getFullYear();
		},
	},
	async beforeMount() {
		await this.getCareers();
		await this.getThesis();
		infiniteScroll(this.getThesis);
		this.schools = await getSchools(this.payload.rol, this.JWT, this);
		if (!this.schools) {
			return;
		}
		if (this.schools.length > 0) {
			this.filters.school_id = this.schools[0].school_id;
			this.establishSchoolFilter();
		}
	},
	computed: {
		...mapState('Token', ['JWT', 'payload']),
	}
}
</script>

<style scoped>

</style>