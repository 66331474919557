import { mapActions } from 'vuex';
import Ajv from "ajv";
import addFormats from "ajv-formats";

mapActions('CustomAlert', ['showAlert']);

/**
 * On scroll if finds bottom then execute the callback
 * @param callback
 */
export const infiniteScroll = (callback) =>  {
    window.onscroll = () => {
        if (document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight) {
            callback();
        }
    }
};
const ajv_ = new Ajv();
addFormats(ajv_);
export const ajv = ajv_;

export const ValidationError = Ajv.ValidationError;