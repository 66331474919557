import Vue from 'vue';
import Vuex from 'vuex';
import * as alerts from '@/store/modules/alerts';
import * as passwords from '@/store/modules/confirmPassword';
import * as token_payload from '@/store/modules/token';

import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';
import dotenv from 'dotenv';
const path = require('path');

dotenv.config({path: path.join(__dirname, '../../.env')});

const ls = new SecureLS({
  encodingType: 'aes',
  encryptionSecret: process.env.VUE_APP_LS_PASSPHRASE || '1\'m V3ry-Str0Ng*'
});


Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  state: {
    title: 'SGT',
    BASE_URL: `${process.env.VUE_APP_SERVER_HOST}${process.env.VUE_APP_SERVER_PORT ? `:${process.env.VUE_APP_SERVER_PORT}` : ''}/`,
    SHOW_FORM: false,
    LOCALE: process.env.VUE_APP_I18N_LOCALE,
    DARK_MODE: true,
  },
  actions: {
    showForm: ({commit}) => {
      commit('SHOW_FORM');
    },
    hideForm: ({commit}) => {
      commit('HIDE_FORM');
    },
    changeLocale: ({commit}, locale) => {
      commit('CHANGE_LOCALE' , locale);
    },
    changeDarkMode: ({commit}) => {
      commit('CHANGE_DARK_MODE');
    },
  },
  mutations: {
    SHOW_FORM: state => state.SHOW_FORM = true,
    HIDE_FORM: state => state.SHOW_FORM = false,
    CHANGE_LOCALE: (state, locale) => state.LOCALE = locale,
    CHANGE_DARK_MODE: (state) => state.DARK_MODE = !state.DARK_MODE,
  },
  modules: {
    CustomAlert: {
      namespaced: true,
      state: alerts.state,
      actions: alerts.actions,
      mutations: alerts.mutations
    },
    ConfirmPassword: {
      namespaced: true,
      state: passwords.state,
      actions: passwords.actions,
      mutations: passwords.mutations
    },
    Token: {
      namespaced: true,
      state: token_payload.state,
      actions: token_payload.actions,
      mutations: token_payload.mutations
    }
  }
})


