<template>
	<v-row justify="center">
		<v-dialog
			width="80%"
			v-model="SHOW_FORM"
			@click:outside="hideForm"
			@keydown.esc="hideForm"
		>
			<v-card>
				<v-card-title>
					<span class="headline" v-t="'components.common.thesis.register_thesis'"></span>
				</v-card-title>
				<v-card-text>
					<v-form
						class="px-sm-4 px-lg-6"
						ref="registerThesis"
					>
						<v-row
							v-cloak
						>
							<v-col
								cols="12"
								sm="12"
								md="8"
							>
								<v-text-field
									:label="this.$t('components.common.thesis.title')"
									clearable
									v-model="thesis['title']"
									:rules="rules.thesis_title"
									required
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="4"
							>
								<v-text-field
									v-model="thesis.year"
									:label="$t('components.common.thesis.year')"
									:max="new Date().getFullYear() + 1"
									:min="min_year"
									type="number"
									:rules="rules.thesis_year"
								/>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								v-if="schools.length > 1"
							>
								<v-autocomplete
									:items="schools"
									item-text="school"
									item-value="school_id"
									v-model="thesis.school_id"
									:label="$tc('common_labels.school', 1)"
									@change="establishCareers"
								>
								</v-autocomplete>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								:md="schools.length > 1 ? 6 : 4"
							>
								<v-autocomplete
									:items="select_careers"
									item-text="career"
									item-value="id"
									v-model="thesis.career_id"
									:label="$tc('components.common.thesis.career', 1)"
									@change="establishSpecializations($event)"
									:no-data-text="$t('components.common.thesis.no_data_career')"
								>
								</v-autocomplete>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								:md="schools.length > 1 ? 6 : 4"
							>
								<v-autocomplete
									:items="select_specializations"
									item-text="name"
									clearable
									item-value="id"
									v-model="thesis.specialization_id"
									:label="$tc('components.common.thesis.specialization', 1)"
									:no-data-text="thesis.career_id ? $t('components.common.thesis.no_specializations') : $t('components.common.thesis.no_data_specialization')"
								>
								</v-autocomplete>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								:md="schools.length > 1 ? 6 : 4"
							>
								<v-text-field
									:label="this.$t('components.common.thesis.thesis_uri')"
									:rules="rules.thesis_uri"
									v-model="thesis.thesis_uri"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="4"
							>
								<v-text-field
									v-model="thesis.authors"
									:rules="rules.thesis_authors"
									:label="$t('components.common.thesis.authors')"
									:hint="$t('components.common.thesis.hint_authors_comma_separated')"
								>
								</v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="4"
							>
								<v-text-field
									:label="$t('components.common.thesis.keywords')"
									:rules="rules.thesis_keywords"
									:hint="$t('components.common.thesis.hint_keywords_comma_separated')"
									v-model="thesis.keywords"
								>
								</v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="4"
							>
								<v-text-field
									:label="$tc('components.common.thesis.adviser', 0)"
									:rules="rules.thesis_advisers"
									:hint="$t('components.common.thesis.hint_advisers_comma_separated')"
									v-model="thesis.advisers"
								>
								</v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
							>
								<v-textarea
									append-icon="clear"
									:label="$t('components.common.thesis.abstract')"
									v-model="thesis.abstract"
									:rules="rules.thesis_abstract"
									auto-grow
									@click:append="clearAbstract"
									rows="3"
								></v-textarea>
							</v-col>
						</v-row>
						<v-row
							justify="center"
						>
							<v-progress-linear
								indeterminate
								color="primary"
								class="mb-0"
								:active="show_progress_bar"
							></v-progress-linear>
						</v-row>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="secondary"
						text
						@click="cancelRegister"
						v-t="'common_labels.cancel'"
					>
					</v-btn>
					<v-btn
						color="primary"
						text
						:loading="show_progress_bar"
						@click="registerThesis"
						:disabled="disabled"
						v-t="'components.common.thesis.upload_thesis'"
					>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import {
	thesis_title,
	thesis_abstract,
	thesis_authors,
	thesis_advisers,
	thesis_keywords,
	thesis_year, thesis_uri
} from '@/utils/validations/rules';
import { mapState, mapActions } from 'vuex';
import { HTTP_AUTH } from '@/plugins/axios-http-common';
import { axiosResponseError } from '@/utils/HTTP_handlers';
import { FILES } from '@/utils/validations/regex'
import {getSchools} from "@/utils/repeated_actions";

export default {
	props: {
		careers: []
	},
	data() {
		return {
			thesis: {
				title: '',
				school_id: undefined,
				career_id: undefined,
				specialization_id: undefined,
				thesis_uri: null,
				abstract: '',
				advisers: '',
				authors: '',
				keywords: '',
				year: new Date().getFullYear()
			},
			rules: {
				thesis_title: thesis_title(this),
				thesis_abstract: thesis_abstract(this),
				thesis_authors: thesis_authors(this),
				thesis_advisers: thesis_advisers(this),
				thesis_keywords: thesis_keywords(this),
				thesis_uri: thesis_uri(this),
				thesis_year: thesis_year(this),
			},
			select_careers: [],
			select_specializations: [],
			show_progress_bar: false,
			disabled: false,
			valid_images: FILES.PDF,
			min_year: 0,
			schools: []
		}
	},
	methods:{
		...mapActions(['hideForm']),
		...mapActions('CustomAlert', ['showAlert']),
		establishCareers() {
			this.select_careers = this.careers.filter(c => c.school_id === this.thesis.school_id);
			this.min_year = new Date(
				(this.schools
					.find(s => s.school_id === this.thesis.school_id)
				)['school_foundation']).getFullYear();
			this.thesis.specialization_id = undefined;
			this.thesis.career_id = undefined;
			this.select_specializations = [];
		},
		clearAbstract() {
			this.thesis.abstract = '';
		},
		addDropFile(e) {
			this.thesis.thesis_file = e.dataTransfer.files[0];
		},
		establishSpecializations(career_id) {
			this.select_specializations = this.careers.find(career => career.id === career_id).specializations;
		},
		cancelRegister() {
			this.show_progress_bar = false;
			this.disabled = false;
			this.hideForm();
		},
		async registerThesis() {
			if (this.disabled)
				return;

			if (this.min_year > this.thesis.year) {
				this.disabled = false;
				this.showAlert({text: this.$t('components.common.thesis.year_error'), type: 'warning', icon: 'warning'});
				return;
			}

			this.disabled = true;
			if (!this.thesis.specialization_id)
				this.thesis.specialization_id = undefined;

			this.show_progress_bar = true;

			let data = {...this.thesis};
			data.authors = data.authors ? data.authors.split(',').map(e => e.trim()) : undefined;
			data.advisers = data.advisers ? data.advisers.split(',').map(e => e.trim()) : undefined;
			data.keywords = data.keywords ? data.keywords.split(',').map(e => e.trim()) : undefined;
			data.year = isNaN(data.year) ? new Date().getFullYear() : parseInt(data.year.toString());

			await HTTP_AUTH(this.JWT)
				.put(`${this.payload.rol}/register-thesis`, data)
				.then(response => {
				if (response.status === 200) {
					this.showAlert({text: this.$t('components.common.thesis.registered'), type: 'success', icon: 'check_circle'});
					this.$refs.registerThesis.reset();
					this.$emit('registered-thesis');
				}
				this.hideForm();
			}).catch(err => {
				axiosResponseError(this, err);
			});

			this.show_progress_bar = false;
			setTimeout(() => {
				this.disabled = false;
			}, 500);
		},
	},
	computed: {
		...mapState(['SHOW_FORM']),
		...mapState('Token', ['JWT', 'payload']),
	},
	async mounted() {
		this.schools = await getSchools(this.payload.rol, this.JWT, this);
		if (this.schools.length > 0) {
			this.thesis.school_id = this.schools[0].school_id;
			this.establishCareers();
		}
	}
}
</script>

<style scoped>

</style>