<template>
  <div class="fab-container">
    <Alert
        v-for="alert in alerts"
        :key="alert.id"
        :alert="alert"
    />
  </div>
</template>

<script>
import Alert from '@/components/common/Alerts/Alert';
import { mapState } from 'vuex';

export default {
  name: 'AlertList',
  components: {
    Alert
  },
  computed: {
    ...mapState('CustomAlert', ['alerts'])
  }

}
</script>

<style scoped>
.fab-container {
  position: fixed;
  bottom: 0;
  right: 0;
}
</style>